<script>
import {mapGetters} from 'vuex';
import {onBeforeWindowUnload} from '@/utils/beforeWindowUnload';

// 게임 설정 모달
import * as pokerAPI from "@/plugins/apiHelper_game";
import {GameType, getGameStatusSort, getRouteNameByGameType} from "@/gameCommon";
import ListMixin from "../../mixins/ListMixin";

export default {
  mixins: [ListMixin],
  components: {
    MaintenanceNotice: () => import(/* webpackChunkName: "maintenance-notice-v2" */ '@/components/MaintenanceNotice.vue'),
    CommentNotice: () => import(/* webpackChunkName: "comment-notice-v2" */ '@/components/CommentNotice.vue'),
    CommonHeader: () => import(/* webpackChunkName: "common-header-v2" */ '@/components/CommonHeader.vue'),
    RoomListItem: () => import(/* webpackChunkName: "room-list-item-v2" */ '@/components/RoomListItem.vue'),
    RoomTournamentListItem: () => import(/* webpackChunkName: "room-tournament-list-item-v2" */ '@/components/RoomTournamentListItem.vue'),
    TournamentInfoLayerModal: () => import(/* webpackChunkName: "tournament-info-layer-lobby-v2" */ '@/components/Game/Modal/TournamentInfoLayer.vue'),
    NetworkErrorModal: () => import(/* webpackChunkName: "maintenance-notice-v2" */ '@/components/Game/Modal/NetworkError.vue'),
  },
  data() {
    return {
      displayListType : 'relay',
      displayRowType: 'all',
      roomInfoInterval: null,
      loading: false,
      roomList: [],
      tournamentList: [],
      sortByType: 'buyin',
      sortOrder: {
        buyin: 1,
        blind: 1,
        count: 1,
      },
      now: Number(new Date()),
      selectedTournamentRoomInfo: {
        roomCode: 0,
        roomUri: '',
        roomInfo: {},
      },
    }
  },
  computed: {
    ...mapGetters(['token', 'sessionData']),
    playerId() {
      if (!this.token) {
        return -1;
      }

      return (this.sessionData && this.sessionData.sn) || -1;
    },
  },
  methods: {
    onJoinGame({token, roomCode, data}) {
      window.removeEventListener('beforeunload', onBeforeWindowUnload);
      const querystring = [`roomCode=${roomCode}`, `token=${token}`].join('&');
      window.location.href = `${data.roomUrl}?${querystring}`;
    },
    async getRoomList() {
      try {
        if (this.loading) {
          //alert('잠시 후 다시 시도해주세요');
          console.error('잠시 후 다시 시도해주세요');
          return;
        }

        this.loading = true;

        //방리스트 조회
        const data = await pokerAPI.getRoomList({loginToken: this.token});
        const roomAllList = data.info.roomList || [];

        //릴레이방 설정
        const roomList = roomAllList.filter(list => list?.gameinfo?.type === GameType.Relay ) || [];
        this.roomList.splice(0, this.roomList.length, ...roomList);

        //토너먼트방 설정
        const tournamentList = roomAllList.filter(list => list?.gameinfo?.type === GameType.Tournament ) || [];
        this.tournamentList.splice(0, this.tournamentList.length, ...tournamentList);

        //리스트 정렬
        this.sortBy(this.sortByType, false);

        //참여중인 방 체크
        const joinedRoom = data.info.joinedRoom || {};
        this.alreadyJoinedCheck(joinedRoom, roomAllList);

      } catch (e) {
        // this.$swal.fire({
        //   title: '방 정보를 불러오는 중 오류가 발생했습니다.',
        //   icon: "error",
        // });
        // alert('방 정보를 불러오는 중 오류가 발생했습니다.');
        clearInterval(this.roomInfoInterval);
        console.log(e)
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },
    alreadyJoinedCheck(joinedRoomData, roomList){
      if (!joinedRoomData.isJoined) {
        console.log('[lobby] not exists joined room');
        return;
      }else{
        console.log(`[lobby] exists joined room ${joinedRoomData.gameSN}`);
      }

      const room = roomList.find(room => room.sn === joinedRoomData.gameSN);
      if (!room) {
        console.log('[lobby] room not found');
        return;
      }

      if (joinedRoomData.isGameOver) {
        console.log('[lobby] game over');
        return;
      }

      //플레이중인 게임일경우 이동!
      if (!joinedRoomData.isObserver) {
        window.removeEventListener('beforeunload', onBeforeWindowUnload);
        const routeName = getRouteNameByGameType(joinedRoomData.gameType)
        const uri = joinedRoomData.gameUri;
        window.location.href = `${uri}/${routeName}?roomCode=${joinedRoomData.gameSN}&token=${this.token}`;
      }
    },
    getSortByValues(type, a, b) {
      switch (type) {
        case 'count':
          return [a.players.length, b.players.length];
        case 'buyin':
          return [
              a.gameinfo.maxBuyin === '*' ? 999999999 : a.gameinfo.buyin + a.gameinfo.maxBuyin,
              b.gameinfo.maxBuyin === '*' ? 999999999 : b.gameinfo.buyin + b.gameinfo.maxBuyin,
          ];
        case 'blind':
          return [a.gameinfo.blindTable[0].bb, b.gameinfo.blindTable[0].bb];
      }
    },
    sortBy(type, isToggle) {
      const isActivated = this.sortByType === type;

      if (isToggle) {
        this.sortOrder[type] = isActivated ? -this.sortOrder[type] : this.sortOrder[type];
      }

      this.sortByType = type;

      //멀티 소팅을 위한 타입별 소팅 우선순위 설정
      const sortTypes = ['buyin', 'blind', 'count'];
      const index = sortTypes.indexOf(type);
      sortTypes.splice(index, 1);
      sortTypes.unshift(type);

      //바이인 기본소팅의 경우 인원수 멀티소팅을 제외(요청사항)
      if( type === 'buyin' ){
        const removeSortIndex = sortTypes.indexOf('count');
        sortTypes.splice(removeSortIndex, 1);
      }

      //릴레이방 리스트 정렬 시작!
      this.roomList = [...this.roomList].sort((a, b) => {
        let sorValue = 0;
        //각 소팅 타입별 우선순으로 비교
        for( let sortType of sortTypes ){
          let [aValue, bValue] = this.getSortByValues(sortType, a, b);
          if( aValue !== bValue ){
            sorValue = this.sortOrder[sortType] * ((aValue < bValue) ? -1 : (aValue > bValue) ? 1 : 0);
            break;
          }
        }
        return sorValue;
      });

      //토너먼트방 리스트 정렬 시작!
      this.tournamentList = [...this.tournamentList].sort((a, b) => {
        if (getGameStatusSort(a.gameStatus) > getGameStatusSort(b.gameStatus)) return 1 // 소팅값이 작은순
        else if (getGameStatusSort(a.gameStatus) < getGameStatusSort(b.gameStatus)) return -1
        else return Number(new Date(a.openTime)) - Number(new Date(b.openTime));
      });
      // console.log(this.tournamentList.filter(item => item.sn === 152))
    },
    onLogout() {
      clearInterval(this.roomInfoInterval);
    },
    isDisplay( type ){
      return this.displayListType === type;
    },
    changeDisplay( type ){
      if( ['relay', 'tournament'].includes(type) ){
        this.displayListType = type;
        this.setBrowserDpQuery();
      }
    },
    updateNow() {
      this.now = Number(new Date()); // 1초마다 업데이트
    },
    isActiveDisplayRowType(displayRowType){
      return this.displayRowType === displayRowType;
    },
    changeDisplayRowType(displayRowType) {
      if( ['all', '6', '9'].includes(displayRowType) ){
        this.displayRowType = displayRowType;
        this.setBrowserDpQuery();
      }
    },
    isDisplayRow(displayRowType){
      return this.displayRowType === String(displayRowType) || this.displayRowType ==='all';
    },
    showTournamentInfoModal({roomCode, roomUri}){
      //토너 리스트에서 정보조회
      const selectedRoom = this.tournamentList.find(function(list){
        if( list.sn === roomCode ){
          return true;
        }
      });

      if( selectedRoom ){
        //선택한 토너먼트 정보 설정
        this.selectedTournamentRoomInfo.roomCode = roomCode;
        this.selectedTournamentRoomInfo.roomUri = roomUri;
        this.selectedTournamentRoomInfo.roomInfo = selectedRoom?.tournamentInfo || {};
        this.$refs.tournamentInfoModal.modal.show();
      }
    },
    setBrowserDpQuery(){
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set('dp', this.displayListType);
      currentUrl.searchParams.set('dpRow', this.displayRowType);
      window.history.replaceState({}, '', currentUrl);
    }
  },
  created() {
    setInterval(this.updateNow, 1000);
    this.getRoomList();
    this.roomInfoInterval = setInterval(this.getRoomList, 5000);
  },
  mounted() {
    const {dp, dpRow} = this.$route.query;
    this.changeDisplay(dp);
    this.changeDisplayRowType(dpRow);
  },
  beforeDestroy() {
    clearInterval(this.roomInfoInterval);
    // window.opener.postMessage('close', window.location.origin);
  }
}
</script>

<template>
  <div id="wrap" class="wrap_main">
    <CommonHeader @logout="onLogout" />
    <div class="content">
      <div class="section-notice">
        <MaintenanceNotice />
        <CommentNotice />
      </div>
      <div class="wrap_game_tab">
        <ul class="game_tab">
          <li :class="{active:isDisplay('relay')}" @click.stop.prevent="changeDisplay('relay')">
            <a href="javascript:void(0);"><span class="ico_spade me-2"></span>홀덤</a>
          </li>
          <li :class="{active:isDisplay('tournament')}" @click.stop.prevent="changeDisplay('tournament')">
            <a href="javascript:void(0);"><span class="ico_trophy me-2"></span>토너먼트</a>
          </li>
        </ul>
      </div>
      <div class="wrap_list_room">
        <div class="list_top" v-show="isDisplay('relay')">
          <div class="area_left">
            <div class="item_btn"><button type="button" :class="{active: isActiveDisplayRowType('all')}" @click="changeDisplayRowType('all')">ALL</button></div>
            <div class="item_btn"><button type="button" :class="{active: isActiveDisplayRowType('6')}" @click="changeDisplayRowType('6')"><span class="material-symbols-rounded align-middle" style="font-size:16px;">person</span><span class="align-middle">6인</span></button></div>
            <div class="item_btn"><button type="button" :class="{active: isActiveDisplayRowType('9')}" @click="changeDisplayRowType('9')"><span class="material-symbols-rounded align-middle me-1" style="font-size:21px;">groups</span><span class="material-symbols-rounded"></span><span class="align-middle">9인</span></button></div>
          </div>
          <div class="area_sort">
            <div>
              <button type="button" :class="sortByType === 'buyin' && 'active'" @click.prevent.stop="sortBy('buyin', true)">
                <span>바이인</span>
                <span class="material-symbols-rounded">
                {{ `arrow_drop_${sortOrder.buyin === 1 ? 'up' : 'down'}` }}
              </span>
              </button>
            </div>

            <div>
              <button type="button" :class="sortByType === 'blind' && 'active'" @click.prevent.stop="sortBy('blind', true)">
                <span>블라인드</span>
                <span class="material-symbols-rounded">
                {{ `arrow_drop_${sortOrder.blind === 1 ? 'up' : 'down'}` }}
              </span>
              </button>
            </div>

            <div>
              <button type="button" :class="sortByType === 'count' && 'active'" @click.prevent.stop="sortBy('count', true)">
                <span>인원</span>
                <span class="material-symbols-rounded">
                {{ `arrow_drop_${sortOrder.count === 1 ? 'up' : 'down'}` }}
              </span>
              </button>
            </div>
          </div>
        </div>

        <!-- 홀덤 룸리스트 -->
        <div class="list_room list_holdem" v-show="isDisplay('relay')">
          <template v-for="(room, i) in roomList">
            <RoomListItem
              :key="i"
              :roomNumber="room.sn"
              :roomUri="room.uri"
              :title="room.gameinfo.title"
              :type="room.gameinfo.type"
              :limit="room.gameinfo.playerCnt"
              :count="room.players.length"
              :buyin="room.gameinfo.buyin"
              :maxBuyin="room.gameinfo.maxBuyin"
              :smallBlind="room.gameinfo.blindTable[0].sb"
              :bigBlind="room.gameinfo.blindTable[0].bb"
              :playerCnt="room.gameinfo.playerCnt"
              @join="onJoinGame"
              v-show="isDisplayRow(room.gameinfo.playerCnt)"
            />
          </template>
        </div>
        <!-- 토너먼트 룸리스트 -->
        <div class="list_room list_tournament"  v-show="isDisplay('tournament')">
          <div v-if="tournamentList.length < 1" class="no_list">현재 참여 가능한 방이 없습니다.</div>
          <template v-for="(room, i) in tournamentList">
            <RoomTournamentListItem
                :key="i"
                :roomNumber="room.sn"
                :roomUri="room.uri"
                :title="room.gameinfo.title"
                :type="room.gameinfo.type"
                :buyin="room.gameinfo.buyin"
                :maxBuyin="room.gameinfo.maxBuyin"
                :limit="room.gameinfo.playerCnt"
                :count="room.players.length"
                :status="room.gameStatus"
                :totalPrize="room.gameinfo.totalPrize"
                :entryCnt="room.gameinfo.entryCnt"
                :curEntryCnt="room.gameinfo.curEntryCnt"
                :entryBlindLimit="room.gameinfo.entryBlindLimit"
                :curBlindSeq="room.gameinfo.curBlindSeq"
                :blindTable="room.gameinfo.blindTable"
                :tGameStart="room.tGameStart"
                :openTime="room.gameinfo.openTime"
                :now="now"
                @show="showTournamentInfoModal"
                :isMain="false"
            />
          </template>
        </div>
      </div>
      <TournamentInfoLayerModal
          ref="tournamentInfoModal"
          layerType="lobby"
          :roomCode="selectedTournamentRoomInfo.roomCode"
          :roomUri="selectedTournamentRoomInfo.roomUri"
          :tournamentInfo="selectedTournamentRoomInfo.roomInfo"
          :userSn="Number(sessionData.sn)"
          @join="onJoinGame"
      />
    </div>
    <NetworkErrorModal />
  </div>
</template>