<script>
import * as pokerAPI from "../../plugins/apiHelper_game";
import {mapGetters} from "vuex";
import {onBeforeWindowUnload} from '@/utils/beforeWindowUnload';
import ListMixin from "../../mixins/ListMixin";
import {getRouteNameByGameType} from "@/gameCommon";

export default {
  mixins: [ListMixin],
  components: {
    MaintenanceNotice: () => import(/* webpackChunkName: "maintenance-notice-v2" */ '@/components/MaintenanceNotice.vue'),
    CommentNotice: () => import(/* webpackChunkName: "comment-notice-v2" */ '@/components/CommentNotice.vue'),
    CommonHeader: () => import(/* webpackChunkName: "common-header-v2" */ '@/components/CommonHeader.vue'),
    RoomTournamentListItem: () => import(/* webpackChunkName: "room-tournament-list-item-v2" */ '@/components/RoomTournamentListItem.vue'),
    TournamentInfoLayerModal: () => import(/* webpackChunkName: "tournament-info-layer-lobby-v2" */ '@/components/Game/Modal/TournamentInfoLayer.vue'),
    NetworkErrorModal: () => import(/* webpackChunkName: "maintenance-notice-v2" */ '@/components/Game/Modal/NetworkError.vue'),
  },
  data(){
    return {
      loading: false,
      roomInfoInterval: null,
      roomInfo: {
        joinedRoom: {},
        mainTournamentRooms: [],
        relayNineRoomCnt: 0,
        relayNineRoomUserCnt: 0,
        relaySixRoomCnt: 0,
        relaySixRoomUserCnt: 0,
      },
      selectedTournamentRoomInfo: {
        roomCode: 0,
        roomUri: '',
        roomInfo: {},
      },
      now: Number(new Date()),
    }
  },
  computed:{
    ...mapGetters(['token', 'sessionData']),
  },
  methods:{
    async getMainData(){
      try {
        if (this.loading) {
          //alert('잠시 후 다시 시도해주세요');
          console.error('잠시 후 다시 시도해주세요');
          return;
        }

        this.loading = true;

        const req = await pokerAPI.getMainData({loginToken: this.token});
        const info = req.info || undefined;
        if( info ){
          this.roomInfo.joinedRoom = info?.joinedRoom || {};
          this.roomInfo.relaySixRoomCnt = info?.relaySixRoomCnt || 0;
          this.roomInfo.relaySixRoomUserCnt = info.relaySixRoomUserCnt || 0;
          this.roomInfo.relayNineRoomCnt = info.relayNineRoomCnt || 0;
          this.roomInfo.relayNineRoomUserCnt = info.relayNineRoomUserCnt || 0;
          this.roomInfo.mainTournamentRooms = info.mainTournamentRooms || [];
          this.alreadyJoinedCheck(this.roomInfo.joinedRoom);
        }
      } catch (e) {
        // this.$swal.fire({
        //   title: '메인 페이지 정보를 불러오는 중 오류가 발생했습니다.',
        //   icon: "error",
        // })
        clearInterval(this.roomInfoInterval);
        console.log(e);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },
    alreadyJoinedCheck(joinedRoomData){
      if (!joinedRoomData.isJoined) {
        console.log('[lobby] not exists joined room');
        return;
      }else{
        console.log(`[lobby] exists joined room ${joinedRoomData.gameSN}`);
      }

      if (joinedRoomData.isGameOver) {
        console.log('[lobby] game over');
        return;
      }

      //플레이중인 게임일경우 이동!
      if (!joinedRoomData.isObserver) {
        window.removeEventListener('beforeunload', onBeforeWindowUnload);
        const routeName = getRouteNameByGameType(joinedRoomData.gameType)
        const uri = joinedRoomData.gameUri;
        window.location.href = `${uri}/${routeName}?roomCode=${joinedRoomData.gameSN}&token=${this.token}`;
      }
    },
    updateNow() {
      this.now = Number(new Date()); // 1초마다 업데이트
    },
    onJoinGame({token, roomCode, data}) {
      window.removeEventListener('beforeunload', onBeforeWindowUnload);
      const querystring = [`roomCode=${roomCode}`, `token=${token}`].join('&');
      window.location.href = `${data.roomUrl}?${querystring}`;
    },
    showTournamentInfoModal({roomCode, roomUri}){
      //토너 리스트에서 정보조회
      const selectedRoom = this.roomInfo.mainTournamentRooms.find(function(room){
        if( room.sn === roomCode ){
          return true;
        }
      });

      if( selectedRoom ){
        //선택한 토너먼트 정보 설정
        this.selectedTournamentRoomInfo.roomCode = roomCode;
        this.selectedTournamentRoomInfo.roomUri = roomUri;
        this.selectedTournamentRoomInfo.roomInfo = selectedRoom?.tournamentInfo || {};
        this.$refs.tournamentInfoModal.modal.show();
      }
    },
    goLobby({dpType, dpRowType}){
      this.$router.push({name:'lobby', query:{dp: dpType, dpRow: dpRowType}});
    },
    onLogout() {
      clearInterval(this.roomInfoInterval);
    },
  },
  created() {
    setInterval(this.updateNow, 1000);
    this.getMainData();
    this.roomInfoInterval = setInterval(this.getMainData, 5000);
  },
  beforeDestroy() {
    clearInterval(this.roomInfoInterval);
  }
}
</script>

<template>
  <div id="wrap" class="wrap_main">
    <CommonHeader @logout="onLogout" />
    <div class="section-notice">
      <MaintenanceNotice />
      <CommentNotice />
    </div>
    <div class="main_content content">

      <div class="row">
        <section class="main_spot col-lg-7">
          <div id="mani_bnr" class="carousel slide">
            <div class="carousel-indicators">
<!--              <button type="button" data-bs-target="#mani_bnr" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>-->
              <!--<button type="button" data-bs-target="#mani_bnr" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#mani_bnr" data-bs-slide-to="2" aria-label="Slide 3"></button> -->
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="@/assets/v2/img/img_spot.png" class="img_spot d-block w-100" alt="...">
              </div>
              <!-- <div class="carousel-item">
                  <img src="@/assets/v2/img/img_spot.png" class="d-block w-100" alt="...">
              </div>
              <div class="carousel-item">
                  <img src="@/assets/v2/img/img_spot.png" class="d-block w-100" alt="...">
              </div> -->
            </div>
<!--            <button class="carousel-control-prev" type="button" data-bs-target="#mani_bnr" data-bs-slide="prev">-->
<!--              <span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
<!--              <span class="visually-hidden">Previous</span>-->
<!--            </button>-->
<!--            <button class="carousel-control-next" type="button" data-bs-target="#mani_bnr" data-bs-slide="next">-->
<!--              <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
<!--              <span class="visually-hidden">Next</span>-->
<!--            </button>-->
          </div>
        </section>
        <div class="mt-lg-0 mt-4 col-lg-5">
          <section class="main_section container section_holdem">
            <div class="list_header">
              <div class="tit"><span class="ico_spade me-2"></span><span>홀덤</span></div>
              <a href="javascript:void(0);" @click="goLobby({dpType: 'relay', dpRowType: 'all'})" class="btn_more">
                <span class="align-middle txt">전체보기</span>
                <img src="@/assets/v2/svg/icon_arrow_forward.svg" class="align-middle" alt="바로가기">
              </a>
            </div>
            <div class="list_room row g-4">
              <div class="col-6">
                <div class="room_info user01">
                  <div class="player_num">
                    <span class="ico_user"></span>
                    <div>6인</div>
                  </div>
                  <div class="area_txt_info d-lg-block">
                    <div class="txt_info">
                      <div class="tit_room"><img src="@/assets/v2/svg/ico_player.svg" alt="">플레이어 수</div>
                      <div class="info_num point_color">{{ roomInfo.relaySixRoomUserCnt }}</div>
                    </div>
                    <div class="txt_info">
                      <div class="tit_room"><img src="@/assets/v2/svg/ico_table.svg" alt="">테이블 수</div>
                      <div class="info_num point_color">{{ roomInfo.relaySixRoomCnt }}</div>
                    </div>
                  </div>
                  <div>
                    <a href="javascript:void(0);" @click="goLobby({dpType: 'relay', dpRowType: '6'})" class="btn_line_point">바로시작</a>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="room_info user03">
                  <div class="player_num">
                    <span class="ico_user03"></span>
                    <div>9인</div>
                  </div>
                  <div class="area_txt_info d-lg-block">
                    <div class="txt_info">
                      <div class="tit_room"><img src="@/assets/v2/svg/ico_player.svg" alt="">플레이어 수</div>
                      <div class="info_num">{{ roomInfo.relayNineRoomUserCnt }}</div>
                    </div>
                    <div class="txt_info">
                      <div class="tit_room"><img src="@/assets/v2/svg/ico_table.svg" alt="">테이블 수</div>
                      <div class="info_num">{{ roomInfo.relayNineRoomCnt }}</div>
                    </div>
                  </div>
                  <div>
                    <a href="javascript:void(0);" @click="goLobby({dpType: 'relay', dpRowType: '9'})" class="btn_line_point">바로시작</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="col-12">
          <section class="main_section container section_tourna mt-4">
            <div class="list_header">
              <div class="tit"><span class="ico_trophy me-2"></span><span>토너먼트</span></div>
              <a href="javascript:void(0);" @click="goLobby({dpType: 'tournament', dpRowType: 'all'})" class="btn_more">
                <span class="align-middle txt">전체보기</span>
                <img src="@/assets/v2/svg/icon_arrow_forward.svg" class="align-middle" alt="바로가기">
              </a>
            </div>
            <div class="list_room row g-4">
              <div v-if="roomInfo.mainTournamentRooms.length < 1" class="no_list">현재 참여 가능한 방이 없습니다.</div>
              <template v-for="(room, i) in roomInfo.mainTournamentRooms">
                <RoomTournamentListItem
                    :key="i"
                    :roomNumber="room.sn"
                    :roomUri="room.uri"
                    :title="room.gameinfo.title"
                    :type="room.gameinfo.type"
                    :buyin="room.gameinfo.buyin"
                    :maxBuyin="room.gameinfo.maxBuyin"
                    :limit="room.gameinfo.playerCnt"
                    :count="room.players.length"
                    :status="room.gameStatus"
                    :totalPrize="room.gameinfo.totalPrize"
                    :entryCnt="room.gameinfo.entryCnt"
                    :curEntryCnt="room.gameinfo.curEntryCnt"
                    :entryBlindLimit="room.gameinfo.entryBlindLimit"
                    :curBlindSeq="room.gameinfo.curBlindSeq"
                    :blindTable="room.gameinfo.blindTable"
                    :tGameStart="room.tGameStart"
                    :openTime="room.gameinfo.openTime"
                    :now="now"
                    @show="showTournamentInfoModal"
                    :isMain="true"
                />
              </template>
            </div>
          </section>
        </div>
      </div>
      <TournamentInfoLayerModal
          ref="tournamentInfoModal"
          layerType="lobby"
          :roomCode="selectedTournamentRoomInfo.roomCode"
          :roomUri="selectedTournamentRoomInfo.roomUri"
          :tournamentInfo="selectedTournamentRoomInfo.roomInfo"
          :userSn="Number(sessionData.sn) || 0"
          @join="onJoinGame"
      />
    </div>
    <NetworkErrorModal />
  </div>
</template>